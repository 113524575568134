import axios from 'axios';

const vlink = {
  async getCurrentLink() {
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   params: {
    //   },
    // };

    // const url = `${process.env.VUE_APP_API_GATEWAY}/video-link`;

    // const response = await axios.get(url, config);
    const response = new Promise((resolve) => {
      const urlMan = 'https://youtu.be/BIefsCEP1-k';
      resolve({
        data: {
          isShowing: true,
          vUrl: urlMan,
        },
      });
    });
    return response;
  },
  async updateVideoLink(data) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const url = `${process.env.VUE_APP_API_GATEWAY}/video-link`;

    const response = await axios.patch(url, data, config);
    return response;
  },
};

export default vlink;
