<template>
  <button>
    <div v-show="!isLoading"><slot></slot></div>
    <div v-show="isLoading" class='loader'>
      <div class="originalText"><slot></slot></div>
      <div class="lds-heart">
        <div class="heart"></div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'LoaderButton',
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
  },
  computed: {},
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
  },
  watch: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
