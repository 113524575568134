<template>
   <div id="rsvp" class="space-evenly one">
      <div>
      <button @click="toggleRSVPVisibility" class="noselect">
        Get Location Details
      </button>
      </div>
      <transition name="fade">
      <div class="backdrop" v-show="rsvpShowing">
        <div class="modal" v-if="!isOnRsvpList">
            <div class="exit" @click="rsvpShowing = false">
              <div class="header"><span class="head">Verification</span></div>
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          <section v-show="!rsvpVerificationFailed &&
                          usingIphone &&
                          iPhoneVersion <= 13">
            <div>
              <p>Woops, it appears your browser is incompatible with this form.
                Please try with submitting your RSVP with Google Chrome, Firefox, or
                a newer version of Safari.
                If you are still unsuccessful, please email us at
                <a href="mailto:saravandzachl@gmail.com">saravandzachl@gmail.com</a>.
              </p>
            </div>
          </section>
          <section v-show="!rsvpVerificationFailed &&
                          !(usingIphone &&
                          iPhoneVersion <= 13)">
            <div class="rsvp-item">
              <label>First and last name of one person in your party</label>
              <input
                v-model="verifyName"
                @keyup.enter="rsvpVerification()"
                ref="rsvpVerify"
                type="text"
                placeholder="John Doe" />
            </div>
            <div class="center-content">
              <loader-button
                ref="verifyingPartyLoaderButton"
                @click="rsvpVerification()">Verify</loader-button>
            </div>
          </section>
          <section v-show="rsvpVerificationFailed">
            <div>
              <p>Sorry, it appears that we could not find you on our list.
                You may have a typo in your name so feel free to
                <a class="back" @click="resetPartyVerification()">
                  click here</a> to try again.
                If that doesn't work and you feel this might be incorrect, please email
                us at
                <a href="mailto:saravandzachl@gmail.com">saravandzachl@gmail.com</a>.
              </p>
            </div>
          </section>
        </div>
        <div class="modal" v-else-if="showAcceptedPage || (showAlreadyRsvpdPage && !needsReview)">
          <div class="exit" @click="rsvpShowing = false">
            <div class="header"><span class="head">Thank you!</span></div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <section>
            <p>Here are the details for this event:
            </p>
            <section class="short-schedule">
              <div class="address">The Vista at Cedar Hills Golf Club</div>
              <div class="address">10640 N, Clubhouse Dr, Cedar Hills, UT 84062</div>
              <div>&nbsp;</div>
              <div class="day">Saturday, June 18, 2022</div>
              <div class="time-event">Ceremony - 5:30 PM</div>
              <div class="time-event">Reception - 6:00 PM - 9:30 PM</div>
            </section>
            <p>If you would like to update your RSVP, please
              <a class="back" @click="resetFormToUpdateRsvp()">click here</a>!
            </p>
            <calendar-button />
          </section>
        </div>
        <div class="modal" v-else-if="showMissYouPage">
          <div class="exit" @click="rsvpShowing = false">
            <div class="header"><span class="head">Thank you!</span></div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <section>
            <p>We are sorry to hear you can't make it! The event will be streamed and
              you are still invited to attend virtually. Check back here on June 18th
              for a link to the event.
            </p>
            <p>If you would like to update your RSVP, please
              <a class="back" @click="resetFormToUpdateRsvp()">click here</a>!
            </p>
            <calendar-button />
          </section>
        </div>
        <div class="modal" v-else-if="showWeGetBackToYouPage ||
                                      (showAlreadyRsvpdPage && needsReview)">
          <div class="exit" @click="rsvpShowing = false">
            <div class="header"><span class="head">Thank you!</span></div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <section>
            <p>Thank you for sending your RSVP! <span class="emphasis">If it's at all
              possible, we will accomodate your request for more seating and let you
              know right away!</span>
            </p>
            <p>If you would like to update your RSVP, please
              <a class="back" @click="resetFormToUpdateRsvp()">click here</a>! In the
              meantime, feel free to add this event to your calendar!
            </p>
            <calendar-button />
          </section>
        </div>
        <div class="modal" v-else-if="showErrorPage">
          <div class="exit" @click="rsvpShowing = false">
            <div class="header"><span class="head">Thank you!</span></div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <section>
            <p>Something strange happened. :(
            </p>
          </section>
        </div>
        <div class="modal" v-else-if="isOnRsvpList">
          <div class="exit" @click="rsvpShowing = false">
            <div class="header"><span class="head">Get Location Details</span></div>
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <section>
            <p>We have {{seatMessage}} reserved {{lapInfantMessage}} {{partyMessage}}.
            </p>
            <div class="rsvp-item">
              <div class="item-option">
                <input type="checkbox" v-model="unableToAttend">
                {{apologyMessage}}
              </div>
              <div :class="{
                  'space-evenly': true,
                  two: true,
                  disabled: !seatChangeRequested || unableToAttend,
                  completely: unableToAttend,
                }">
                <label>Chairs</label>
                <label>Lap Children</label>
                <input pattern="\d*" type="number" min=1 v-model="changeSeatCount" placeholder=""/>
                <input pattern="\d*"
                  type="number"
                  min=0
                  v-model="changeLapInfantCount"
                  placeholder=""/>
              </div>
              <div class="item-option">
                <input type="checkbox" :class="{
                    disabled: unableToAttend,
                    completely: unableToAttend,
                  }" v-model="seatChangeRequested">
                Update attendees
              </div>
            </div>
            <div :class="{
                'rsvp-item': true,
                'space-evenly': true,
                one: true,
                disabled: unableToAttend,
                completely: unableToAttend,
              }">
              <label>Food Restrictions</label>
              <input v-model="allergiesAndDietaryRestrictions"
                :class="{
                    fakeholder: true,
                    'fakeholder-disabled': disableAllergyFakeholder
                  }"
                @blur="setAllergyText(false)"
                @focus="setAllergyText(true)"
                placeholder='Eggs, Peanuts, Milk, etc.'
                type="textarea" />
            </div>
            <div class="center-content">
              <loader-button
                ref="submittingRsvpLoaderButton"
                @click="submitRSVP()">
                Send
              </loader-button>
            </div>
            <p class="disclaimer">Due
              to limited space, we greatly appreciate you letting us know if any of these
              chairs will not be needed.
            </p>
          </section>
        </div>
      </div>
      </transition>
    </div>
</template>

<script>
import LoaderButton from '@/components/LoaderButton.vue';
import CalendarButton from '@/components/CalendarButton.vue';
import rsvpApi from '../api/rsvp';

export default {
  name: 'Rsvp',
  components: {
    LoaderButton,
    CalendarButton,
  },
  data() {
    return {
      allergiesAndDietaryRestrictions: 'Eggs, Peanuts, Milk, etc.',
      changeLapInfantCount: 1,
      changeSeatCount: 2,
      disableAllergyFakeholder: false,
      isOnRsvpList: false,
      isSubmittingRsvp: false,
      isVerifyingParty: false,
      lapInfantCount: 1,
      name: '',
      needsReview: false,
      party: null,
      rsvpShowing: false,
      rsvpVerificationFailed: false,
      seatChangeRequested: false,
      seatCount: 2,
      showAcceptedPage: false,
      showAlreadyRsvpdPage: false,
      showMissYouPage: false,
      showWeGetBackToYouPage: false,
      showErrorPage: false,
      unableToAttend: false,
      verifyName: '',
      usingIphone: false,
      iPhoneVersion: Infinity,
    };
  },
  props: {
  },
  mounted() {
    const ua = window.navigator.userAgent;

    this.isOniOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    this.matchesWebKit = !!ua.match(/WebKit/i);
    const versionMatches = ua.match(/Version\/([0-9.]+)/);
    if (versionMatches) {
      this.iPhoneVersion = parseInt(versionMatches[1], 10);
    }

    this.usingIphone = this.isOniOS && this.matchesWebKit && !ua.match(/CriOS/i);
  },
  computed: {
    apologyMessage() {
      if (this.party.chairCount + this.party.babyCount === 1) {
        return 'I send my apologies, as I will be unable to attend.';
      }
      return 'We send our apologies, as we will be unable to attend.';
    },
    lapInfantMessage() {
      if (this.party.babyCount === 0) {
        return '';
      }
      if (this.party.babyCount === 1) {
        return ' with 1 lap child ';
      }
      return ` with ${this.party.babyCount} lap children node `;
    },
    partyMessage() {
      if (this.party.chairCount + this.party.babyCount === 1) {
        return 'for you';
      }
      return 'for your party';
    },
    seatMessage() {
      if (this.party.chairCount === 1) {
        return 'your chair';
      }
      return `${this.party.chairCount} chairs`;
    },
  },
  methods: {
    resetFormToUpdateRsvp() {
      this.showAcceptedPage = false;
      this.showAlreadyRsvpdPage = false;
      this.showMissYouPage = false;
      this.needsReview = false;
      this.isUpdatingRsvp = true;
      this.isOnRsvpList = true;
    },
    resetPartyVerification() {
      this.rsvpVerificationFailed = false;
      this.isSubmittingRsvp = false;
    },
    submitRSVP() {
      if (this.isSubmittingRsvp) {
        return;
      }
      this.isSubmittingRsvp = true;
      const { submittingRsvpLoaderButton } = this.$refs;
      submittingRsvpLoaderButton.startLoading();

      // determine what this RSVP is all about
      if (this.unableToAttend) {
        this.makeRSVPRequest('not-attending', {
          rsvp: {
            partyId: this.party.id,
            isComing: false,
            requestedChange: false,
          },
        }, this.party.id);
        return;
      }

      let { allergiesAndDietaryRestrictions } = this;
      let seatCount = this.party.chairCount;
      let lapInfantCount = this.party.babyCount;

      if (allergiesAndDietaryRestrictions === 'Eggs, Peanuts, Milk, etc.') {
        allergiesAndDietaryRestrictions = '';
      }

      if (this.seatChangeRequested) {
        seatCount = this.changeSeatCount;
        lapInfantCount = this.changeLapInfantCount;

        let status = 'requested-update';
        if (this.changeSeatCount <= this.seatCount) {
          status = 'attending';
        }

        this.makeRSVPRequest(status, {
          rsvp: {
            partyId: this.party.id,
            isComing: true,
            requestedChange: true,
            newChairCount: seatCount,
            newBabyCount: lapInfantCount,
            allergiesAndDietaryRestrictions,
          },
        }, this.party.id);
        return;
      }

      this.makeRSVPRequest('attending', {
        rsvp: {
          partyId: this.party.id,
          allergiesAndDietaryRestrictions,
          requestedChange: false,
          isComing: true,
        },
      }, this.party.id);
    },
    async makeRSVPRequest(status, data, partyId) {
      // const result = await rsvpApi.submit(data);
      const result = await rsvpApi.updateParty(data, partyId);
      this.isSubmittingRsvp = false;

      const { submittingRsvpLoaderButton } = this.$refs;
      submittingRsvpLoaderButton.stopLoading();

      if (result.status !== 204) {
        this.showErrorPage = true;
        return;
      }

      switch (status) {
        case 'attending': {
          this.showAcceptedPage = true;
          break;
        }
        case 'not-attending': {
          this.showMissYouPage = true;
          break;
        }
        case 'requested-update': {
          this.showWeGetBackToYouPage = true;
          break;
        }
        default:
          this.showErrorPage = true;
      }
    },
    async rsvpVerification() {
      // Make request to api to get party member
      try {
        const veryifyButtonLoader = this.$refs.verifyingPartyLoaderButton;
        veryifyButtonLoader.startLoading();
        const response = await rsvpApi.getPartyByPartyMember(this.verifyName.toLowerCase());
        veryifyButtonLoader.stopLoading();
        this.party = response.data;
        this.changeSeatCount = this.party.chairCount;
        this.changeLapInfantCount = this.party.babyCount;
        this.showAlreadyRsvpdPage = this.party.hasSubmittedRsvp;
        this.needsReview = this.party.needsReview;
        this.isOnRsvpList = true;
      } catch (e) {
        if (e.response.status === 404) {
          const veryifyButtonLoader = this.$refs.verifyingPartyLoaderButton;
          veryifyButtonLoader.stopLoading();
          this.isOnRsvpList = false;
          this.rsvpVerificationFailed = true;
        }
      }
    },
    setAllergyText(isEntering) {
      if (this.allergiesAndDietaryRestrictions === 'Eggs, Peanuts, Milk, etc.'
          && isEntering) {
        this.disableAllergyFakeholder = false;
        this.allergiesAndDietaryRestrictions = '';
        return;
      }
      if (this.allergiesAndDietaryRestrictions.trim() === ''
          && !isEntering) {
        this.disableAllergyFakeholder = false;
        this.allergiesAndDietaryRestrictions = 'Eggs, Peanuts, Milk, etc.';
        return;
      }
      this.disableAllergyFakeholder = true;
    },
    toggleRSVPVisibility() {
      this.rsvpShowing = !this.rsvpShowing;
      if (!this.isOnRsvpList) {
        this.$nextTick(() => {
          this.$refs.rsvpVerify.focus();
        });
      }
    },
  },
  watch: {
    allergiesAndDietaryRestrictions(value) {
      if (value !== 'Eggs, Peanuts, Milk, etc.') {
        this.disableAllergyFakeholder = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
