import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import UpdateLink from '../views/UpdateLink.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'S & Z: Welcome',
    },
  },
  {
    path: '/debug',
    name: 'Debug',
    meta: {
      title: 'Debug',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Debug.vue'),
  },
  {
    path: '/update-video-link',
    name: 'Update Link',
    meta: {
      title: 'Update Link',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateLink.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
