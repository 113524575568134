<template>
  <div class="tile">
    <button @click="toggleModalVisibility" class="noselect">
      <font-awesome-icon :icon="[faStyle, faIcon]" size="3x" color="hsl(170, 0%, 20%)" />
      <div class="subtitle">{{tileText}}</div>
    </button>
    <transition name="fade">
    <div class="backdrop" v-show="isShowing">
      <div class="modal">
        <div class="exit" @click="isShowing = false">
          <div class="header"><span class="head">{{tileText}}</span></div>
          <font-awesome-icon :icon="['fas', 'times']" />
        </div>
        <slot name="modal"></slot>
      </div>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ImageTileWithModal',
  data() {
    return {
      isShowing: false,
    };
  },
  props: {
    faStyle: String,
    faIcon: String,
    imgLocation: String,
    tileText: String,
  },
  methods: {
    toggleModalVisibility() {
      this.isShowing = !this.isShowing;
    },
  },
  mounted() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
