import axios from 'axios';

const rsvp = {
  async submit(data) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
      },
    };

    const url = `${process.env.VUE_APP_API_GATEWAY}/rsvps`;

    const response = await axios.post(url, data, config);
    return response;
  },
  async getPartyByPartyMember(partyMember) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
      },
    };

    const url = `${process.env.VUE_APP_API_GATEWAY}/parties?name=${partyMember}`;

    const response = await axios.get(url, config);
    return response;
  },
  async updateParty(data, partyId) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        id: partyId,
      },
    };

    const url = `${process.env.VUE_APP_API_GATEWAY}/parties/${partyId}`;

    const response = await axios.patch(url, data, config);
    return response;
  },
};

export default rsvp;
