<template>
  <div class="home">
    <div id="banner" class="noselect">
      Sara & Zach
    </div>
    <section id="live" @click="goToLiveStream()">
      <div class="red" v-if="hasStarted">
        <div class="centered">
          Live!
          Started {{this.timeLeftS}}! Click Here to Watch
        </div>
      </div>
      <div class="blue" v-else-if="!hasEnded">
        <div class="centered">
          Starting {{this.timeLeftS}}! Click Here to Watch
        </div>
      </div>
    </section>
    <section class="hype-date">
      <div class="noselect">
        <!-- <div>Sara<br /> & Zach</div>
        <div>We're tying the knot! Come celebrate with us!</div> -->
        <div class="hashtag">#SaraAndZachWed</div>
        <div class="basic">on</div>
        <div class="day">June 18th</div>
        <div class="basic">in</div>
        <div class="place">Cedar Hills, UT</div>
      </div>
    </section>
    <main id="story">
      <h1>Our Story</h1>
      <h3>His</h3>
      <p>Sara and I worked at the same company for a few years before a
         fateful re-organization found us sat near one
         another. It was her kindness and strong work ethic that
         really captured my eye.
         I like to think I won her heart over with my plucky nature
         and a few well-placed paper airplanes. We hit it off and have
         been adventuring together ever since!</p>
      <h3>Hers</h3>
      <p>Zach became my cubicle neighbor after a fortuitous seating change.
        Once I got to know him, I was allured
        by his many good qualities; he has intelligence, integrity, and
        communication skills (although his aim with paper airplanes was
        somewhat questionable). I knew we had something special when we
        began dating, and the last few years have only cemented that
        knowledge. Now, I’m excited to see where life leads us!</p>
    </main>
    <section id="details">
      <h1>Wedding Details</h1>
      <p>We're excited to celebrate our marriage with our families and dearest friends!
      You have been important in our lives, and we want to share this experience with you.
      For those who will watch the ceremony virtually, you will be able to access the link
      from here on the day of the wedding. For those attending in person, check out the
      helpful information and RSVP at the bottom of the page.</p>
      <p class="emphasize center">Children of all ages are welcome.</p>
      <section class="short-schedule">
        <div class="day">Saturday, June 18, 2022</div>
        <div class="time-event">Ceremony - 5:30 PM</div>
        <div class="time-event">Reception - 6:00 PM - 9:30 PM</div>
      </section>
    </section>
    <section id="information">
      <h1>Masks and COVID vaccination</h1>
      <p>We want everyone to feel safe celebrating with us.
      Much will depend on the public health situation in June,
      so we will post updates as we get closer.
      We reserve the right to ask for testing, masks,
      and/or distancing if we feel it necessary to ensure the safety of all our guests.</p>

      <h1>Food and drink</h1>
      <p>The reception will include a dinner of catered food.
      We will do our best to accommodate any dietary needs that
      you indicate in your RSVP. The venue does
      not allow alcohol, but a variety of non-alcoholic drink
      options will be provided.</p>

      <h1>Pictures</h1>
      <p>You are welcome to take pictures of the ceremony and reception
      if you wish! You are also welcome to simply enjoy the experience,
      as we will have a professional photographer on site.</p>
      <p>If you decide to take photos, we have two requests:
      <ul>
        <li>Please do not block the professional photographer or videographer’s
      shots. Especially during the ceremony, we ask that you stay in your
      seat so as to keep all the sight lines clear.</li>
        <li>We would especially love it if you tag us on any photos posted
          online. (#SaraAndZachWed)</li>
      </ul>
      </p>
    </section>
    <section class="modals">
      <image-tile-with-modal :tileText="'Gifts'"
        :faStyle="'fas'"
        :faIcon="'gift'">
        <template v-slot:modal>
          <p>We have all the possessions we need, so our favorite
          gift would be your presence! Come ready to
          celebrate and create great memories.</p>
          <p>Optional: If you would still like a way to contribute,
            we are accepting donations to our honeymoon fund
            (<a target="_blank" href="https://www.travelersjoy.com/saravandzachl/">
              here
            </a>)
            and would appreciate volunteers to help with setup
            and cleanup. Let us know if you're interested in
            volunteering time and we'll talk through options.
          </p>
        </template>
      </image-tile-with-modal>

      <image-tile-with-modal :tileText="'Dress Code'"
        :faStyle="'fab'"
        :faIcon="'black-tie'">
        <template v-slot:modal>
          <p>From semi-fancy to red-carpet-ready, just be yourself and
            have fun! Please note that the ceremony will be held outside
            (if weather permits), and the venue location tends to get a
            little windy. The reception will be indoors.</p>
        </template>
      </image-tile-with-modal>
      <image-tile-with-modal :tileText="'Children'"
        :faStyle="'fas'"
        :faIcon="'child'">
        <template v-slot:modal>
          <p>We welcome children of all ages for both the ceremony and the
          reception. Since space is limited, we do appreciate you letting
          us know in the RSVP section if any of your children will be on
          laps and therefore will not need a seat of their own.</p>
        </template>
      </image-tile-with-modal>
      <image-tile-with-modal :tileText="'When to Arrive'"
        :faStyle="'fas'"
        :faIcon="'clock'">
        <template v-slot:modal>
          <p>We recommend arriving at 5:00 so that you have time to choose
          a seat and settle in. We plan to begin the ceremony promptly at
          5:30 PM.</p>
        </template>
      </image-tile-with-modal>
      <image-tile-with-modal :tileText="'Parking'"
        :faStyle="'fas'"
        :faIcon="'parking'">
        <template v-slot:modal>
          <p>The venue has plenty of parking next to the reception building.</p>
        </template>
      </image-tile-with-modal>
      <image-tile-with-modal :tileText="'Traveling'"
        :faStyle="'fas'"
        :faIcon="'plane'">
        <template v-slot:modal>
          <p>Let us know if you are coming from out of town and are interested in hotel
            recommendations or coordinating a home rental with other guests.</p>
        </template>
      </image-tile-with-modal>
    </section>

    <rsvp />
    <section id="questions">
      <h1>Questions</h1>
      <p class="center">Please email us at
      <a href="mailto:saravandzachl@gmail.com">saravandzachl@gmail.com</a>.
      <!-- <router-link to="/debug"><font-awesome-icon :icon="['fas', 'bug']" /></router-link> -->
      </p>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Rsvp from '@/components/Rsvp.vue';
import moment from 'moment';
import ImageTileWithModal from '../components/ImageTileWithModal.vue';
import videoLinkApi from '../api/vlink';

export default {
  name: 'Home',
  components: {
    ImageTileWithModal,
    Rsvp,
  },
  data() {
    return {
      wantsVirtualLink: false,
      cantAttend: false,
      rsvpShowing: false,
      name: '',
      attendingCount: null,
      lapInfantCount: null,
      vUrl: '',
      showBanner: false,
      allergiesAndDietaryRestrictions: '',
      timer: '',
      timeLeft: null,
      timeLeftS: '',
      hasStarted: false,
      hasEnded: false,
    };
  },
  methods: {
    goToLiveStream() {
      window.open(this.vUrl);
    },
    setLinkInformation() {
      videoLinkApi.getCurrentLink().then((response) => {
        this.vUrl = response.data.vUrl;
        this.showBanner = response.data.isShowing;
      });
    },
    setTimes() {
      this.timeLeftS = moment('2022-06-18T17:30:00').endOf().fromNow();
      this.hasStarted = moment('2022-06-18T17:30:00') < new Date();
      this.hasEnded = moment('2022-06-18T21:30:00') < new Date();
    },
    updateTimeLeft() {
      this.setTimes();
    },
  },
  mounted() {
    this.setLinkInformation();
    this.setTimes();
    this.timer = setInterval(this.updateTimeLeft, 1000);
  },
};
</script>
