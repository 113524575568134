<template>
  <div>
    <button @click="showCalendarLinks = !showCalendarLinks">
      Add To Calendar
    </button>
    <a v-show="showCalendarLinks" href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220618T230000Z%2F20220619T033000Z&details=Go%20to%20%3Ca%20href%3D%22https%3A%2F%2Fsaraandzach.com%22%3Esaraandzach.com%3C%2Fa%3E%20for%20updates%20and%20details.&location=The%20Vista%20at%20Cedar%20Hills%20Golf%20Club%2C%2010640%20N%2C%20Clubhouse%20Dr%2C%20Cedar%20Hills%2C%20UT%2084062%2C%20USA&text=Sara%20and%20Zach%27s%20Wedding"
      class="addToCalendar button"
      target="_blank">Google</a>
    <a v-show="showCalendarLinks" href="sara-and-zach.ics"
      class="addToCalendar button"
      target="_blank">Apple</a>
    <a v-show="showCalendarLinks" href="https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=Go%20to%20%3Ca%20href%3D%22https%3A%2F%2Fsaraandzach.com%22%3Esaraandzach.com%3C%2Fa%3E%20for%20updates%20and%20details.&enddt=2022-06-19T03%3A30%3A00%2B00%3A00&location=The%20Vista%20at%20Cedar%20Hills%20Golf%20Club%2C%2010640%20N%2C%20Clubhouse%20Dr%2C%20Cedar%20Hills%2C%20UT%2084062%2C%20USA&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-06-18T23%3A00%3A00%2B00%3A00&subject=Sara%20and%20Zach%27s%20Wedding"
      class="addToCalendar button"
      target="_blank">Outlook</a>
    <a v-show="showCalendarLinks" href="https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=Go%20to%20%3Ca%20href%3D%22https%3A%2F%2Fsaraandzach.com%22%3Esaraandzach.com%3C%2Fa%3E%20for%20updates%20and%20details.&enddt=2022-06-19T03%3A30%3A00%2B00%3A00&location=The%20Vista%20at%20Cedar%20Hills%20Golf%20Club%2C%2010640%20N%2C%20Clubhouse%20Dr%2C%20Cedar%20Hills%2C%20UT%2084062%2C%20USA&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-06-18T23%3A00%3A00%2B00%3A00&subject=Sara%20and%20Zach%27s%20Wedding"
      class="addToCalendar button"
      target="_blank">Office 365</a>
    <a v-show="showCalendarLinks" href="https://calendar.yahoo.com/?desc=Go%20to%20%3Ca%20href%3D%22https%3A%2F%2Fsaraandzach.com%22%3Esaraandzach.com%3C%2Fa%3E%20for%20updates%20and%20details.&dur=&et=20220619T033000Z&in_loc=The%20Vista%20at%20Cedar%20Hills%20Golf%20Club%2C%2010640%20N%2C%20Clubhouse%20Dr%2C%20Cedar%20Hills%2C%20UT%2084062%2C%20USA&st=20220618T230000Z&title=Sara%20and%20Zach%27s%20Wedding&v=60"
      class="addToCalendar button"
      target="_blank">Yahoo!</a>
  </div>
</template>

<script>
export default {
  name: 'CalendarButton',
  data() {
    return {
      showCalendarLinks: false,
    };
  },
  props: {},
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
