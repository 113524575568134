<template>
  <div id="nav">
    <!-- <router-link to="/">Home</router-link> -->
  </div>
  <router-view/>
</template>
<script>
</script>

<style lang="scss">
// Fonts
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap&family=Lora&display=swap');
@font-face {
  font-family: "Simpletix";
  src: local("Simpletix"),
   url(./fonts/simpletix/simpletix.otf) format("truetype");
}

@import '@/css/variables.scss';
@import '@/css/reset.scss';
@import '@/css/small.scss';
@import '@/css/medium.scss';
@import '@/css/large.scss';
@import '@/css/heart.scss';
@import '@/css/calendar.scss';

</style>
