import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowLeft,
  faBug,
  faChild,
  faClock,
  faGift,
  faParking,
  faPhone,
  faPlane,
  faSocks,
  faTimes,
  faUserPlus,
  faUserTie,
  faWheelchair,
} from '@fortawesome/free-solid-svg-icons';

import {
  faAccessibleIcon,
  faBlackTie,
} from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faAccessibleIcon);
library.add(faArrowLeft);
library.add(faBlackTie);
library.add(faBug);
library.add(faChild);
library.add(faClock);
library.add(faGift);
library.add(faParking);
library.add(faPlane);
library.add(faPhone);
library.add(faSocks);
library.add(faTimes);
library.add(faUserPlus);
library.add(faUserTie);
library.add(faWheelchair);

export default FontAwesomeIcon;
